import { useEffect, useState } from "react";
import PermissionTemplateForm from "../../components/PermissionTemplate/PermissionTemplateForm";
import { Alert, Divider } from "antd";
import { useTranslation } from "react-i18next";


const CreatePermissionTemplate = (customer) => {
    const { t } = useTranslation();
    const [customerId, setCustomerId] = useState();
    const [customerName, setCustomerName] = useState();
    const [componentDisabled, setComponentDisabled] = useState(true);

    const onCustomerSelected = async () => {
        setCustomerId(customer.customer.customerId);
        setCustomerName(customer.customer.customerName);
        setComponentDisabled(false);
    };

    useEffect(() => {
            if (customer.customer !== null) {
                onCustomerSelected();
            }
        },
        [customer]);

    useEffect(() => {
            if (customer.customer !== null) {
                onCustomerSelected();
            }
        },
        []);

    const contents = componentDisabled
        ? <Alert message="Select a customer from the drop down." type="info" showIcon/>
        : <PermissionTemplateForm data={undefined} customerId={customerId} customerName={customerName}/>;

    return (
        <div>
            <h1>{t("permission.CreatePermission") }</h1>
            <Divider/>
            <div className="row">
                <div className="col-12 col-xl-10">
                    {contents}
                </div>
            </div>
        </div>
    );
}

export default CreatePermissionTemplate