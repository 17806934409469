import {ApiCore} from './utilities/api-core';

const url = 'customers';
const plural = 'customers';
const single = 'customer';

// plural and single may be used for message logic if needed in the ApiCore class.

export const apiCustomer = new ApiCore({
    getAll: true,
    getSingle: true,
    post: true,
    put: false,
    patch: true,
    remove: true,
    url: url,
    plural: plural,
    single: single
});

apiCustomer.massUpdate = () => {
    // Add custom api call logic here
}
