import { useEffect, useState } from "react";
import { Divider, Spin, Space, Button, Form, Input } from "antd";
import { apiCustomer } from '../../services/api/apiCustomer';
import history from "../../history";
import { useTranslation } from "react-i18next";

const EditCustomer = (props) => {
    const [customerId, setCustomerId] = useState();
    const [customerName, setCustomerName] = useState();
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
            getCustomer(props.match.params);
        },
        [props.match.params]);

    const getCustomer = (params) => {
        apiCustomer.getSingle(params.id)
            .then(data => {
                setCustomerId(data.customerId);
                setCustomerName(data.customerName)
                setLoading(false);
            });
    }

    const onFinish = (values) => {
        setSaving(true);
        values["id"] = customerId;

        apiCustomer.patch(JSON.stringify(values))
            .then(response => {
                history.push("/customers");
            })
            .catch(error => {
                console.error("There was an error!", error);
            })
            .finally(() => {
                setSaving(false);
            });
    };

    const onDelete = () => {
        apiCustomer.remove(customerId)
            .then(response => {
                history.push("/customers");
            })
            .catch(error => {
                console.error("There was an error!", error);
            });
    };

    const onCancel = () => {
        history.push("/customers");
    };

    return (
        loading
            ? <div>
                  <em>{t("common.Loading") }</em>
                  <Spin/>
              </div>
            : <div className="row">
                  <div className="col-12 col-xl-6">
                      <h1>{t("customer.EditCustomer")}</h1>
                      <Divider/>
                      <Form
                          name="basic"
                          onFinish={onFinish}
                          initialValues={{ name: customerName }}>
                          <Form.Item
                              label={t("customer.CustomerName")}
                              name="name"
                              rules={[{ required: true, message: "Please input the customer name!" }]}>
                              <Input/>
                          </Form.Item>
                          <Form.Item className="float-end">
                            <Space>
                                <Button type="primary" htmlType="submit" loading={saving}>
                                    {t("common.Save")}
                                </Button>
                                <Button onClick={onCancel}>{t("common.Cancel")}</Button>
                                <Button type="primary" danger onClick={onDelete} disabled>
                                    {t("common.Delete")}
                                </Button>
                            </Space>
                          </Form.Item>
                      </Form>
                  </div>
              </div>
    );
}
export default EditCustomer;