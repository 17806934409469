import PermissionTemplateForm from "../../components/PermissionTemplate/PermissionTemplateForm";
import { apiPermission } from "../../services/api/apiPermission";
import { Divider, Spin } from "antd";
import { useState, useEffect } from "react";


const EditPermissionTemplate = (props) => {
    const [loading, setLoading] = useState(true);
    const [permissionTemplateData, setPermissionTemplateData] = useState();

    const getPermissionTemplate = (permissionTemplateId, customerId) => {
        apiPermission.getSingle(permissionTemplateId, `/${customerId}`)
            .then(data => {
                setPermissionTemplateData(data);
                setLoading(false);
            })
            .catch(error => {
                console.error("There was an error loading the template: ", error)
            });
    }

    useEffect(() => {        
        getPermissionTemplate(props.match.params.id, props.match.params.customerId);
    }, [props.match.params]);

    return (
        loading
            ? <>
                  <em>Loading...</em>
                  <Spin/>
              </>
            : <>                
                <h1>Edit Permission Template '{permissionTemplateData.name}'</h1>
                <h3>{permissionTemplateData.Id}</h3>                

                <Divider/>
                <PermissionTemplateForm data={permissionTemplateData} customerId={permissionTemplateData.customer_id}/>
              </>
    );
}
export default EditPermissionTemplate