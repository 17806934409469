import { useState, useEffect } from 'react';
import { Spin, Button, Form, Input, Space } from "antd";
import history from "../../history";
import { apiPerson } from "../../services/api/apiPerson";
import { useTranslation } from "react-i18next";

const EditPerson = (props) => {
    const { t } = useTranslation();
    const [person, setPerson] = useState();
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);

    const onFinish = (values) => {
        setSaving(true);
        values["id"] = person.id;
        values["customer_id"] = person.customer_id;

        apiPerson.patch(JSON.stringify(values))
            .then(response => {
                history.push("/persons");
                setSaving(false);
            })
            .catch(error => {
                console.error("There was an error!", error);
                setSaving(false);
            });
    };

    const onDelete = () => {
        apiPerson.remove(person.id, `/${person.customer_id}`)
            .then(response => {
                history.push("/persons");
            })
            .catch(error => {
                console.error("There was an error!", error);
            });
    };

    const onCancel = () => {
        history.push(`/persons`);
    };

    const getPerson = (params) => {
        apiPerson.getSingle(params.id, `/${params.customerid}`)
            .then(data => {
                const personData = data;
                setPerson(personData);
                setLoading(false);
            })
            .catch(error => {
                console.error("There was an error!", error);
            });
    }

    useEffect(() => {
            getPerson(props.match.params);
        },
        [props.match.params]);

    return (
        loading
            ? <div>
                  <em>{t("common.Loading")}</em>
                  <Spin/>
              </div>
            : <Form
                  name="basic"
                  onFinish={onFinish}
                  initialValues={{
                      first_name: person.first_name,
                      last_name: person.last_name,
                      email_address: person.email_address
                  }}>
                  <div className="row">
                      <div className="col-12 col-xl-6">
                          <Form.Item
                              label={t("person.FirstName")}
                              name="first_name"
                              rules={[{ required: true, message: t("person.FirstNameMessage") }]}>
                              <Input/>
                          </Form.Item>
                          <Form.Item
                              label={t("person.LastName")}
                              name="last_name"
                              rules={[{ required: true, message: t("person.LastNameMessage") }]}>
                              <Input/>
                          </Form.Item>
                          <Form.Item
                              label={t("common.EmailAddress")}
                              name="email_address"
                              rules={[{ required: true, type: "email", message: t("common.EmailAddressMessage") }]}>
                              <Input/>
                          </Form.Item>

                          <Space className="float-end">
                              <Spin spinning={saving}>
                                  <Button type="primary" htmlType="submit">
                                      {t("common.Save")}
                                  </Button>
                              </Spin>
                              <Button onClick={onCancel}>
                                  {t("common.Cancel")}
                              </Button>
                              <Button type="primary" danger onClick={onDelete}>
                                  {t("common.Delete")}
                              </Button>
                          </Space>
                      </div>
                  </div>
              </Form>
    );
}
export default EditPerson