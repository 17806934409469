import { useState } from "react";
import { Select, Spin } from "antd";
import { useTranslation } from "react-i18next";

const LanguageSwitcher = () => {
    const [changingLang, setChangingLang] = useState(false);    
    const { Option } = Select;
    const { i18n } = useTranslation();

    const updateActiveLanguage = () => {
        setChangingLang(true);

        setTimeout(() => {
                setChangingLang(false);
            }, 300);
    }

    const handleOnchange = (e) => {
        i18n.changeLanguage(e);
        updateActiveLanguage(e);
    }

    const handleClick = (e) => {
        e.stopPropagation();
    }

    return (
        <Spin spinning={changingLang}>
            <Select value={i18n.language}
                    className="w-100"
                    onClick={handleClick}
                    onChange={handleOnchange}
                    bordered={true}>
                <Option value="en">English</Option>
                <Option value="sv">Svenska</Option>
            </Select>
        </Spin>
    );
}

export default LanguageSwitcher;