import { useState, useEffect } from "react";
import { Form, Input, Button, Spin, Space, Divider } from "antd";
import history from '../../history';
import { apiPerson } from "../../services/api/apiPerson";
import { useTranslation } from "react-i18next";


const CreatePerson = (customer) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [customerId, setCustomerId] = useState();
    const [saving, setSaving] = useState(false);

    const onFinish = (values) => {
        setSaving(true);
        values["customer_id"] = customerId;

        apiPerson.post(JSON.stringify(values))
            .then(response => {
                setSaving(false);
                history.push('/persons');
            })
            .catch(error => {
                setSaving(false);
                console.error("There was an error!", error);
            });
    };

    useEffect(() => {
            if (customer.customer !== null) {
                setCustomerId(customer.customer.customerId);
                form.setFieldsValue({ customer_name: customer.customer.customerName });
            }
        },
        [customer]);

    useEffect(() => {
        if (customer.customer !== null) {
            setCustomerId(customer.customer.customerId);
            form.setFieldsValue({ customer_name: customer.customer.customerName });
        }
    });

    const onCancel = () => {
        history.push(`/persons`);
    };

    return (
        <div>
            <h1>{t("person.CreatePerson") }</h1>
            <Divider/>
            <Form form={form}
                  name="basic"
                  layout="vertical"
                  initialValues={{ remember: true }}
                  onFinish={onFinish}>
                <div className="row">
                    <div className="col-12 col-xl-6">
                        <Form.Item label={t("customer.Customer")}
                                   name="customer_name"
                                   rules={[{ required: true, message: t("customer.CustomerNameMessage") }]}>
                            <Input placeholder={t("common.SelectCustomer")} readOnly/>
                        </Form.Item>
                        <Form.Item label={t("person.FirstName")} name="first_name"
                                   rules={[{ required: true, message: t("person.FirstNameMessage") }]}>
                            <Input/>
                        </Form.Item>
                        <Form.Item label={t("person.LastName")} name="last_name"
                                   rules={[{ required: true, message: t("person.LastNameMessage") }]}>
                            <Input/>
                        </Form.Item>
                        <Form.Item label={t("common.EmailAddress")} name="email_address"
                                   rules={[
                                       { required: true, type: "email", message: t("common.EmailAddressMessage") }
                                   ]}>
                            <Input/>
                        </Form.Item>

                        <Space className="float-end">
                            <Button htmlType="button" onClick={onCancel}>
                                {t("common.Cancel")}
                            </Button>
                            <Button type="primary" htmlType="submit" style={{ float: 'right' }} disabled={saving}>
                                <Spin spinning={saving}>
                                    {t("common.Submit")}
                                </Spin>
                            </Button>
                        </Space>
                    </div>
                </div>
            </Form>
        </div>
    );
}
export default CreatePerson