import { useState, useEffect } from "react"
import DeDUList from "../../components/List/DeDUList";
import { apiPerson } from "../../services/api/apiPerson";
import { Spin } from "antd";
import { useTranslation } from "react-i18next";

const ListPersons = (customer) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [persons, setPersons] = useState([]);
    const [subTitle, setSubTitle] = useState(t("common.All") + (' ') + t("person.Persons"));

    useEffect(() => {
        onSelectedCustomer();
    },
        []);

    useEffect(() => {
        onSelectedCustomer();
    },
        [customer]);

    useEffect(() => {
        if (customer.customer === null) {
            setSubTitle(t("common.All") + (' ') + t("person.Persons"));
        }
        else {
            setSubTitle(t("common.All") + (' ') + customer.customer.customerName + (' ') + t("person.Persons"));
        }
    },
        [t]);

    const onSelectedCustomer = () => {
        if (customer.customer !== null) {
            getPersons();
            setSubTitle(t("common.All") + (' ') + customer.customer.customerName + (' ') + t("person.Persons"));
        }
    }

    const getPersons = () => {
        setLoading(true);
        apiPerson.getAll(`/${customer.customer.customerId}`)
            .then(data => {
                const personData = data.map(({ id, customer_id, email_address, first_name, last_name }) => ({
                    id: id,
                    customer_id: customer_id,
                    description: email_address,
                    title: first_name + " " + last_name
                }));
                setPersons(personData);
                setLoading(false);
            });
    }

    return (
        loading
            ? <div>
                <em>{t("common.Loading")}</em>
                <Spin />
            </div>
            : <DeDUList array={persons}
                avatarUrl={"https://www.dedu.se/deduweb/images/dedu-logo.png"}
                mainTitle={t("person.Persons")}
                subTitle={subTitle}
                listItemLink={"/persons"} />
    );
}
export default ListPersons