import { useState, useEffect } from "react"
import DeDUList from "../../components/List/DeDUList";
import { Spin } from "antd";
import { apiCustomer } from '../../services/api/apiCustomer';
import { useTranslation } from "react-i18next";

const ListCustomers = () => {
    const [loading, setLoading] = useState(true);
    const [customers, setCustomers] = useState([]);

    const { t } = useTranslation();

    useEffect(() => {
        getCustomers();
    },
        []);

    const getCustomers = () => {
        apiCustomer.getAll()
            .then((data) => {
                const customerData = Object.keys(data.idNames).map(key => ({ id: key, description: key, title: data.idNames[key] }));
                setLoading(false);
                setCustomers(customerData);
        });
    }

    return (
        loading 
            ? <div>
                <em>{t("common.Loading")}</em>
                <Spin />
            </div>
            : <DeDUList array={customers}
                avatarUrl={"https://www.dedu.se/deduweb/images/dedu-logo.png"}
                mainTitle={t("customer.Customers")}
                subTitle={t("customer.AllDeduCustomers")}
                listItemLink={"/customers"} />
        );
}

export default ListCustomers


