import {apiProvider} from './api-provider';

export class ApiCore {
    constructor(options) {
        if (options.getAll) {
            this.getAll = (urlExtension) => {
                return apiProvider.getAll(urlExtension ? options.url + urlExtension : options.url);
            };
        }

        if (options.getSingle) {
            this.getSingle = (id, urlExtension) => {
                return apiProvider.getSingle(urlExtension ? options.url + urlExtension : options.url, id);
            };
        }

        if (options.post) {
            this.post = (model, urlExtension) => {
                return apiProvider.post(urlExtension ? options.url + urlExtension : options.url, model);
            };
        }

        if (options.put) {
            this.put = (model) => {
                return apiProvider.put(options.url, model);
            };
        }

        if (options.patch) {
            this.patch = (model, urlExtension) => {
                return apiProvider.patch(urlExtension ? options.url + urlExtension : options.url, model);
            };
        }

        if (options.remove) {
            this.remove = (id, urlExtension) => {
                return apiProvider.remove(urlExtension ? options.url + urlExtension : options.url, id);
            };
        }
    }
}