import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    .use(HttpApi)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: true,
        fallbackLng: 'en',
       
        interpolation: {
            escapeValue: false
            // not needed for react as it escapes by default
        }
        //resources: {
        //    en: {
        //        translation: {
        //            description: {
        //                part1: 'Edit <1>src/App.js</1> and save to reload.',
        //                part2: 'Learn React'
        //            },
        //            home: {
        //                welcome1: 'Welcome to DeDU Platform Admin'
        //            }
        //        }
        //    },
        //    sv: {
        //        translation: {
        //            description: {
        //                part1: 'Edit <1>src/App.js</1> and save to reload.',
        //                part2: 'Lär dig React'
        //            },
        //            home: {
        //                welcome1: 'Välkommen till DeDU Platform Admin'
        //            }
        //        }
        //    }
        //}
    });

export default i18n;