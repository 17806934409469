import { useEffect, useState } from "react";
import { Divider, Spin, Space, Button, Form, Input } from "antd";
import { apiCustomer } from '../../services/api/apiCustomer';
import history from "../../history";
import { useTranslation } from "react-i18next";
import { apiIdentity } from "../../services/api/apiIdentity";

const EditIdentity = (props) => {
    const [identityId, setIdentityId] = useState();
    const [userName, setUserName] = useState();
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
            getIdentity(props.match.params);
        },
        [props.match.params]);

    const getIdentity = (params) => {
        apiIdentity.getSingle(params.id, `/${params.customerid}`)
            .then(data => {
                setIdentityId(data.loginId);
                setUserName(data.username)
                setLoading(false);
            });
    }

    const onFinish = (values) => {
        setSaving(true);
        values["id"] = identityId;

        apiIdentity.patch(JSON.stringify(values))
            .then(response => {
                history.push("/identities");
            })
            .catch(error => {
                console.error("There was an error!", error);
            })
            .finally(() => {
                setSaving(false);
            });
    };    

    const onDelete = () => {
        apiIdentity.remove(identityId)
            .then(response => {
                history.push("/identities");
            })
            .catch(error => {
                console.error("There was an error!", error);
            });
    };

    const onCancel = () => {
        history.push("/identities");
    };

    return (
        loading
            ? <div>
                  <em>{t("common.Loading") }</em>
                  <Spin/>
              </div>
            : <div className="row">
                  <div className="col-12 col-xl-6">
                    <h1>{t("userAccount.EditUserAccount")}</h1>
                      <Divider/>
                      <Form
                          name="basic"
                          onFinish={onFinish}
                          initialValues={{ user_name: userName }}>
                          <Form.Item
                            label={t("userAccount.UserName")}
                              name="user_name"
                              rules={[{ required: true, message: "Please input the user name!" }]}>
                              <Input/>
                          </Form.Item>
                          <Form.Item className="float-end">
                            <Space>                               
                                <Button onClick={onCancel}>{t("common.Cancel")}</Button>
                                <Button type="primary" danger onClick={onDelete} disabled>
                                      {t("common.Delete")}
                                </Button>
                                <Button type="primary" htmlType="submit" disabled>
                                    <Spin spinning={saving}>
                                        {t("common.Save")}
                                    </Spin>
                                </Button>
                              </Space>
                          </Form.Item>
                      </Form>
                  </div>
              </div>
    );
}
export default EditIdentity;