import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import App from './App';
import { unregister } from './registerServiceWorker';
import history from './history';
import './services/language/i18n/i18n';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

ReactDOM.render(
    <Router history={history}> 
        <React.Suspense fallback="Loading...">
            <App />
        </React.Suspense>
    </Router>,
  rootElement);

unregister();