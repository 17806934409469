import { ApiCore } from './utilities/api-core';

const url = 'languages';
const plural = 'languages';
const single = 'language';

// plural and single may be used for message logic if needed in the ApiCore class.

export const apiLanguage = new ApiCore({
    getAll: true,
    getSingle: true,
    post: true,
    put: false,
    patch: true,
    remove: true,
    url: url,
    plural: plural,
    single: single
});