import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Select, Spin, Space } from "antd"
import { apiCustomer } from '../../services/api/apiCustomer';
import { WarningOutlined } from "@ant-design/icons"
import { useTranslation } from "react-i18next";
import {
    UserSwitchOutlined
} from "@ant-design/icons";

const CustomerSelectSearch = ({ onSelected, margin, inline, selectedCustomer }) => {
    const [customers, setCustomerData] = useState({ data: [] });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const { t } = useTranslation();

    async function getCustomers() {
        apiCustomer.getAll().then((dict) => {
            const customerData = Object.keys(dict.idNames).map(key => ({ id: key, key: key, title: dict.idNames[key] }));
            setCustomerData({ data: customerData });
        }).catch((err) => {
            console.error("Error when fetching customers", err);
            setError(true);
        }).finally(() => {
            setLoading(false);
        });
    };

    useEffect(() => {
        (async () => {
            await getCustomers();
        })();
    }, []);

    const onCustomerChange = (value, event) => {
        if (onSelected) {
            const customer = {
                customerId: event.value,
                customerName: event.children
            }
            onSelected(customer);
        }
    }

    const contents = loading
        ? <div>
              <UserSwitchOutlined style={{ marginLeft: '35px' }}/>
              <Spin style={{ marginLeft: '25px' }}/>
          </div>
        : <div style={{ margin: margin, display: inline ? 'inline' : 'block' }}>
              <Space>
                  <UserSwitchOutlined style={{ marginLeft: '25px' }} className="fs-6"/>
                  <Select placeholder={t("component.CustomerSelectSearch")}
                          onSelect={(value, event) => onCustomerChange(value, event)}
                          defaultValue={selectedCustomer} bordered={false}
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) => option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                          filterSort={(optionA, optionB) => optionA.children?.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                          role="button"
                          style={{ width: 180 }}>
                      {customers.data.map((customer) => (
                          <Select.Option value={customer.id} key={customer.id}>
                              {customer.title}
                          </Select.Option>
                      ))}
                  </Select>
              </Space>

              {error &&
                  <WarningOutlined/>
              }
          </div>;
    return (
        <div>
            {contents}
        </div>
    );
}

CustomerSelectSearch.defaultProps = {
    margin: "10px 0 0",
    inline: false
}

CustomerSelectSearch.propTypes = {
    onSelected: PropTypes.func,
    margin: PropTypes.string
}

export default CustomerSelectSearch;