export function handleResponse(response) {
    if (response.results) {
        return response.results;
    }

    if (response.data) {
        return response.data;
    }

    return response;
}

export function handleError(error) {
    if (error.response.status === 401) {
        /*return window.location.replace("bff/logout");*/
        return window.location.replace("https://admin.furydev.dedu.se");
    }
    if (error.data) {
        return error.data;
    }
    throw error;
}