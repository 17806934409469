import { useState, useRef, useEffect } from "react";
import { Form, Input, Button, Spin, Divider, Space, Alert } from "antd";
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import history from '../../history';
import { apiCustomer } from '../../services/api/apiCustomer';
import { useTranslation } from "react-i18next";
import _debounce from 'lodash/debounce';
import './CreateCustomer.css';

const CreateCustomer = () => {
    const [saving, setSaving] = useState(false);
    const [validating, setValidating] = useState(false);
    const [validationSuccess, setValidationSuccess] = useState();
    const [validationMessage, setValidationMessage] = useState();
    const [customerName, setCustomerName] = useState("");
    const [error, setError] = useState();
    const { t } = useTranslation();

    const onSubmit = (values) => {
        setSaving(true);
        setError({});        
        let customerObject = { "name": customerName };

        apiCustomer.post(JSON.stringify(customerObject))
            .then((response) => {
                history.push('/customers');
            })
            .catch(error => {
                console.error("There was an error!", error);
                setError({ text: "Something went wrong: " + error });
            }).finally(() => {
                setSaving(false);
            });
    };

    const onCancel = () => {
        history.push(`/customers`);
    };   

    async function handleNameChange(e) {
        setValidationMessage();
        setCustomerName(e.target.value);
        if (e.target.value.length) {
            setValidating(true);            
            debouncedValidate(e.target.value);
        } else {
            setValidating(false);
            setValidationSuccess(null);
            setCustomerName("");
        }
    }

    const debouncedValidate = useRef(
        _debounce(async (value) => {
            let customerObject = { "name": value };            
            apiCustomer.post(JSON.stringify(customerObject), "/validate")
                .then((rsp) => {
                    if (rsp.validName && rsp.uniqueName)
                        setValidationSuccess(true);
                    else { 
                        setValidationSuccess(false)
                        setValidationMessage(rsp);
                    }
                })
                .catch(error => {
                    console.error("There was an error!", error);
                }).finally(function () {
                    setValidating(false);
                });
        }, 500)
    ).current;

    useEffect(() => {
        return () => {
            debouncedValidate.cancel();
        };
    }, [debouncedValidate]);

    return (
        <div className="row">
            <div className="col-12 col-xl-6">
                <h1>{t("customer.OnboardCustomer")}</h1>
                <Divider/>
                <Form                    
                    layout="vertical"
                    initialValues={{ remember: true }}
                    onFinish={onSubmit}>
                    <Form.Item className="mb-0"
                        label={t("customer.CustomerName")}
                        name="name"
                        rules={[{ message: t("customer.CustomerNameMessage") }]}>
                        <Input maxLength={50} onChange={handleNameChange} className="customer-input" />

                        {validating && <Spin />}
                        {validationSuccess === true && !validating && <CheckCircleOutlined className="success" />}
                        {validationSuccess === false && !validating && <CloseCircleOutlined className="error" />}
                    </Form.Item>
                    
                    {error && error.text &&
                        <Form.Item>
                            <Alert message={error?.text} type="error" />
                        </Form.Item>
                    }          

                    {validationMessage &&
                        <div className="alert alert-warning my-3" role="alert">
                            {!validationMessage.uniqueName &&
                                <p>Customer name is not unique!</p>
                            }
                            {!validationMessage.validName &&
                                <p>Customer name is not valid!</p>
                            }                            
                        </div>
                    }

                    <div className="float-end my-5">
                        <Space>
                            <Button htmlType="button" onClick={onCancel}>
                                {t("common.Cancel")}
                            </Button>
                            <Button type="primary" htmlType="submit" disabled={saving || !customerName.length || validationSuccess === false || validating}>
                                <Spin spinning={saving}>
                                    {t("common.Submit")}
                                </Spin>
                            </Button>
                        </Space>
                    </div>
                </Form>
            </div>
        </div>
    );
}

export default CreateCustomer