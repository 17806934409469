import { Divider, List, Avatar, Card } from "antd";
import history from '../../history';

const DeDuList = ({ array, avatarUrl, mainTitle, subTitle, listItemLink }) => {

    const route = (item) => {
        let url = listItemLink + `/${item.id}`;
        if (item.customer_id)
            url += `/${item.customer_id}`;
        history.push(url);
    }

    return (
        <div>
            <h1>{mainTitle}</h1>
            <h3>{subTitle}</h3>

            <Divider />

            <Card>
                <List
                    itemLayout="horizontal"
                    dataSource={array}
                    renderItem={item => (
                        <List.Item id={item.id}>
                            <List.Item.Meta style={{ cursor: 'pointer' }}
                                avatar={<Avatar src={avatarUrl} />}
                                title={item.title}
                                onClick={() => route(item)}
                                description={item.description} />
                        </List.Item>
                    )} />
            </Card>
        </div>
    );
}

export default DeDuList;