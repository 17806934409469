import { useState, useEffect } from "react";
import { Divider, Form, Input, Button, Spin, Space } from "antd";
import { apiLanguage } from "../../services/api/apiLanguage";
import history from '../../history';

const CreateLanguage = (customer) => {
    const [form] = Form.useForm();
    const [customerId, setCustomerId] = useState();
    const [customerName, setCustomerName] = useState();
    const [saving, setSaving] = useState(false);

    const onFinish = (values) => {
        setSaving(true);
        values["customer_id"] = customerId;

        apiLanguage.post(JSON.stringify(values))
            .then(response => {
                setSaving(false);
                form.resetFields();
                history.push('/languages');
            })
            .catch(error => {
                console.error("There was an error!", error);
                setSaving(false);
            });
    };

    useEffect(() => {
            if (customer.customer !== null) {
                setCustomerId(customer.customer.customerId);
                setCustomerName(customer.customer.customerName);
                form.setFieldsValue({ customer_name: customer.customer.customerName });
            }
        },
        [customer]);

    useEffect(() => {
        if (customer.customer !== null) {
            setCustomerId(customer.customer.customerId);
            setCustomerName(customer.customer.customerName);
            form.setFieldsValue({ customer_name: customer.customer.customerName });
        }
    });

    const onCancel = () => {
        history.push(`/languages`);
    };

    return (
        <div className="main-card">
            <div className="content">
                    <div>
                        <h1>Create Language</h1>
                    </div>
                </div>

            <Divider />

                        <Form
                name="basic"
                form={form}
                layout="vertical"
                initialValues={{
                    remember: true
                }}
                onFinish={onFinish}>
                <div className="row">
                    <div className="col-12 col-xl-6">
                        <Form.Item label="Customer"
                                   name="customer_name"
                            rules={[{ required: true, message: "Please input the customer name!" }]}>
                            <Input placeholder="Select Customer" readOnly/>

                        </Form.Item>

                        <Form.Item
                            label="Language name"
                            name="language_name"
                            rules={[{ required: true, message: "Please input the language name!" }]}>
                            <Input/>
                        </Form.Item>

                        <Form.Item
                            label="Language code"
                            name="language_code"
                            rules={[{ required: true, message: "Please input the language description!" }]}>
                            <Input/>
                        </Form.Item>

                        <Space className="float-end">
                            <Button htmlType="button" onClick={onCancel}>
                                Cancel
                            </Button>
                            <Button type="primary" htmlType="submit" style={{ float: 'right' }} disabled={saving}>
                                <Spin spinning={saving}>
                                    Submit
                                </Spin>
                            </Button>
                        </Space>
                    </div>
                </div>
            </Form>

        </div>
        );
}

export default CreateLanguage