import { useState, useEffect } from "react";
import { Menu, Divider, Spin } from "antd";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import UserDropdown from "./UserDropdown";
import CustomerSelectSearch from "./Customer/CustomerSelectSearch";
import { useTranslation } from "react-i18next";
import { Navbar, NavbarBrand, Nav, NavItem, NavLink } from "reactstrap";
import "./MainLayout.css";

const { SubMenu } = Menu;

const MainLayout = ({ parentCallback, children }) => {
    const [customer, setCustomer] = useState();
    const { t } = useTranslation();
    const [logoutUrl, setLogoutUrl] = useState("bff/logout");
    const [loggedIn, setLoggedIn] = useState(false);
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        (async () => {
            await fetchIsUserLoggedIn();
        })();
    }, []);

    const sendData = (data) => {
        parentCallback(data);
    };

    const onCustomerSelected = (data) => {
        sendData(data);
        setCustomer(data);
    };

    const fetchIsUserLoggedIn = async () => {        
        try {            
            setLoading(true);
            const response = await fetch("bff/user", {
                headers: {
                    "X-CSRF": 1,
                },
            });

            if (response.ok && response.status === 200) {
                const data = await response.json();
                const logoutUrl =
                    data.find((claim) => claim.type === "bff:logout_url")?.value ?? logoutUrl;
                //console.log(data);
                setLoggedIn(true);
                setLogoutUrl(logoutUrl);
            }
            setLoading(false);
        } catch (e) {
            console.error(e);
            setLoggedIn(false);
            setLoading(false);
        }        
    };

    return (
        <div id="root">
            <header>
                <Navbar className="navbar-expand-sm navbar-dark bg-dark" light>
                    <div className="container-fluid">
                        <NavbarBrand>
                            <div className="nav-logo">
                                <img src="./wsp.svg" alt="wsp-logo" />
                            </div>
                        </NavbarBrand>
                        <Nav className="mr-auto" navbar>
                            <NavItem className="nav-company-text ms-3 mb-1">
                                <h4 className="text-white">DeDU FuRy Admin</h4>
                            </NavItem>
                        </Nav>

                        <NavItem>
                            {loggedIn ? 
                                <UserDropdown logoutUrl={logoutUrl} />        
                                :
                                loading ?
                                    <Spin /> :
                                    <a className="text-white fs-24 nav-link" href="bff/login">Login</a>
                            }
                        </NavItem>                           
                    </div>
                </Navbar>
            </header>

            {loggedIn &&
                <div className="container-fluid overflow-hidden">
                    <div className="row">
                        <div className="col-12 col-lg-3 col-xl-2 p-0 d-flex">
                            <div className="d-flex flex-sm-column flex-row flex-grow-1 align-items-center align-items-sm-start overflow-hidden">

                                <div className="bg-white w-100">
                                    <CustomerSelectSearch
                                        onSelected={onCustomerSelected}
                                        selectedCustomer={customer} />
                                    <Divider className="mb-1 mt-3" />
                                </div>
                                <div className="w-100">
                                    <Menu mode="inline"
                                        className="vh-100 pt-4 dedu-menu"
                                        defaultSelectedKeys={["1"]}
                                        defaultOpenKeys={["1"]}>
                                        <Menu.Item key="1" icon={<i className="fs-6 fas fa-home" />}>
                                            Start
                                            <Link to="/" />
                                        </Menu.Item>
                                        <SubMenu key="sub1" icon={<i className="fs-6 fas fa-users" />}
                                            title={t("customer.Customer")}>
                                            <Menu.Item key="11">
                                                {t("customer.Customers")}
                                                <Link to="/customers" />
                                            </Menu.Item>
                                            <Menu.Item key="12">
                                                {t("customer.OnboardCustomer")}
                                                <Link to="/customer/create" />
                                            </Menu.Item>
                                        </SubMenu>

                                        <SubMenu key="sub3" icon={<i className="fs-6 fas fa-user" />}
                                            title={t("userAccount.UserAccount")}>
                                            <Menu.Item key="31">
                                                {t("userAccount.UserAccounts")}
                                                <Link to="/identities" />
                                            </Menu.Item>
                                            <Menu.Item key="32">
                                                {t("userAccount.CreateUserAccount")}
                                                <Link to="/identity/create" />
                                            </Menu.Item>
                                        </SubMenu>

                                        <SubMenu key="sub6" icon={<i className="fs-6 fas fa-unlock" />}
                                            title={t("permission.Permission")}>
                                            <Menu.Item key="61">
                                                {t("permission.Permissions")}
                                                <Link to="/permissiontemplates" />
                                            </Menu.Item>
                                            <Menu.Item key="62">
                                                {t("permission.CreatePermission")}
                                                <Link to="/permissiontemplate/create" />
                                            </Menu.Item>
                                        </SubMenu>

                                        {/*<SubMenu key="sub9" icon={<i className="fs-6 fas fa-language" />} title={"Language"}>*/}
                                        {/*    <Menu.Item key="91">{"Languages"}<Link to="/languages" /></Menu.Item>*/}
                                        {/*    <Menu.Item key="92">{"Create Language"}<Link to="/language/create" /></Menu.Item>*/}
                                        {/*</SubMenu>*/}

                                    </Menu>
                                </div>
                            </div>
                        </div>
                        <div className="col d-flex flex-column h-sm-100 overflow-auto m-3 mt-4">
                            {children}
                        </div>
                    </div>
                </div>        
            }               
            </div>
        );
};

export default MainLayout;