import { useState, useEffect } from "react"
import DeDUList from "../../components/List/DeDUList";
import { apiLanguage } from "../../services/api/apiLanguage";
import { Spin } from "antd";
import { useTranslation } from "react-i18next";

const ListLanguages = (customer) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [languages, setLanguages] = useState([]);
    const [subTitle, setSubTitle] = useState(t("common.All") + (' ') + t("language.Languages"));

    useEffect(() => {
        onSelectedCustomer();
    },
        []);

    useEffect(() => {
        onSelectedCustomer();
    },
        [customer]);

    useEffect(() => {
        if (customer.customer === null) {
            setSubTitle(t("common.All") + (' ') + t("language.Languages"));
        }
        else {
            setSubTitle(t("common.All") + (' ') + customer.customer.customerName + (' ') + t("language.Languages"));
        }
    },
        [t]);

    const onSelectedCustomer = () => {
        if (customer.customer !== null) {
            getLanguages();
            setSubTitle(t("common.All") + (' ') + customer.customer.customerName + (' ') + t("language.Languages"));
        }
    }

    const getLanguages = () => {
        setLoading(true);
        apiLanguage.getAll(`/${customer.customer.customerId}`).then((data) => {
            const languagesData = data.map(({ id, customer_id, language_name, language_description }) => ({
                id: id,
                customer_id: customer_id,
                description: language_description,
                title: language_name
            }));
            setLanguages(languagesData)
        }).catch((err) => {
            console.error("Error when fetching customers", err);
        }).finally(() => {
            setLoading(false);
        });
    }
    return (
        loading
            ? <div>
                <em>Loading...</em>
                <Spin />
            </div>
            : <DeDUList array={languages}
                avatarUrl={"https://www.dedu.se/deduweb/images/dedu-logo.png"}
                mainTitle={t("language.Languages")}
                subTitle={subTitle}
                listItemLink={"/languages"} />
    );
}
export default ListLanguages