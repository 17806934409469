import { useState, useEffect } from "react"
import DeDUList from "../../components/List/DeDUList";
import { apiIdentity } from "../../services/api/apiIdentity";
import { Spin } from "antd";
import { useTranslation } from "react-i18next";

const ListIdentities = (customer) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [identities, setIdentities] = useState([]);
    const [subTitle, setSubTitle] = useState(t("common.All") + (' ') + t("userAccount.UserAccounts"));

    useEffect(() => {
        onSelectedCustomer();
    },
        []);

    useEffect(() => {
        onSelectedCustomer();
    },
        [customer]);

    useEffect(() => {
        if (customer.customer === null) {
            setSubTitle(t("common.All") + (' ') + t("userAccount.UserAccounts"));
        }
        else {
            setSubTitle(t("common.All") + (' ') + customer.customer.customerName + (' ') + t("userAccount.UserAccounts"));
        }
    },
        [t]);

    const onSelectedCustomer = () => {
        if (customer.customer !== null) {
            getIdentities();
            setSubTitle(t("common.All") + (' ') + customer.customer.customerName + (' ') + t("userAccount.UserAccounts"));
        }
    }

    const getIdentities = () => {
        setLoading(true);
        apiIdentity.getAll(`/${customer.customer.customerId}`)
            .then(data => {
                const identityData = data.identities.map(({ loginId, customerId, username }) => ({
                    id: loginId,
                    customer_id: customerId,
                    title: username
                }));
                setIdentities(identityData);
                setLoading(false);
            });
    }

    return (
        loading
            ? <div>
                <em>{t("common.Loading")}</em>
                <Spin />
            </div>
            : <DeDUList array={identities}
                avatarUrl={"https://www.dedu.se/deduweb/images/dedu-logo.png"}
                mainTitle={t("userAccount.UserAccounts")}
                subTitle={subTitle}
                listItemLink={"/identities"} />
    );
}
export default ListIdentities