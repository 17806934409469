import { useState, useEffect } from "react";
import { apiPermission } from "../../services/api/apiPermission";
import { Form, Input, Button, Switch, Row, Col, Divider, Typography, Space } from "antd";
import history from "../../history";
import { useTranslation } from "react-i18next";
import { PermissionCheckboxList } from "../../components/PermissionTemplate/PermissionsCheckboxList";

//import { PersonCommandServicePermissionFlags, PersonQueryServicePermissionFlags } from "../../permissionFlags/PersonPermissionFlags";
import { CustomerCommandServicePermissionFlags, CustomerQueryServicePermissionFlags } from "../../permissionFlags/CustomerPermissionFlags";
import { CaseCommandServicePermissionFlags, CaseQueryServicePermissionFlags } from "../../permissionFlags/CorrectiveMaintenancePermissionFlags";
import { ObjectModelServicePermissionFlags } from "../../permissionFlags/ObjectModelPermissionFlags";
//import { PermissionTemplatePermissionFlags } from "../../permissionFlags/PermissionTemplatePermissionFlags";


const { Text, Title } = Typography;
const defaultItems = {
    personCmdPermissions: ["None"],
    personQueryPermissions: ["None"],
    customerCmdPermissions: ["None"],
    customerQueryPermissions: ["None"],
    correctiveMaintenanceCmdPermissions: ["None"],
    correctiveMaintenanceQueryPermissions: ["None"],
    objectModelCmdPermissions: ["None"],
    objectModelQueryPermissions: ["None"],
    permissionTemplatePermissions: ["None"]
};

const PermissionTemplateForm = (formInput) => {
    const { t } = useTranslation();
    const [saving, setSaving] = useState(false);
    let permissionTemplateData = formInput.data;
    let isAdmin = false;
    let initialTemplateName = null;
    let initItems = defaultItems;
    let permissionTemplateId = null;
    let customerId = formInput.customerId;
    let customerName = formInput.customerName;
    const [inputValues, setInputValues] = useState({});
    const [form] = Form.useForm();

    if (permissionTemplateData !== undefined) {
        loadFormWithData();
    }

    useEffect(() => {
        form.setFieldsValue({ customer_name: customerName });
    }, [customerName]);


    useEffect(() => {
            setInputValues(initItems);
    }, [initItems]);


    function loadFormWithData() {
        permissionTemplateId = permissionTemplateData.permission_template_id;
        initItems = permissionTemplateData.service_permissions;
        initialTemplateName = permissionTemplateData.name;
        form.setFieldsValue({ customer_name: customerName });

        if (permissionTemplateData.name === "Admin") {
            isAdmin = true;
        }
    }

    const onFinish = (values) => {        
        setSaving(true);
        values["permissions"] = inputValues;
        values["customer_id"] = customerId;
        values["permission_template_id"] = permissionTemplateId;

        apiPermission.post(JSON.stringify(values))
            .then(() => {
                history.push("/permissionTemplates");                
            })
            .catch(error => {
                console.error("There was an error!", error);
            }).finally(() => {
                setSaving(false);
            });
    };

    function updateInputValues(name, values) {
        setInputValues({ ...inputValues, [name.name]: values });
    }

    const onCancel = () => {
        history.push("/permissiontemplates");
    };

    const onIsAdminChange = (isAdmin) => {
        let templateNameValue = "";

        if (isAdmin) {
            templateNameValue = "Admin";
        }

        form.setFieldsValue({
            permission_template_name: templateNameValue
        });
    };

    const stringIsNumber = value => isNaN(Number(value)) === false;

    function enumToArray(enumToConvert) {
        return Object.keys(enumToConvert)
            .filter(stringIsNumber)
            .map(key => enumToConvert[key]);
    }

    //const userAccountCmdPermissions = enumToArray(UserAccountCommandServicePermissionFlags);
    //const userAccountQueryPermissions = enumToArray(UserAccountQueryServicePermissionFlags);
    //const personCmdPermissions = enumToArray(PersonCommandServicePermissionFlags);
    //const personQueryPermissions = enumToArray(PersonQueryServicePermissionFlags);
    const customerCmdPermissions = enumToArray(CustomerCommandServicePermissionFlags);
    const customerQueryPermissions = enumToArray(CustomerQueryServicePermissionFlags);
    const correctiveMaintenanceCmdPermissions = enumToArray(CaseCommandServicePermissionFlags);
    const correctiveMaintenanceQueryPermissions = enumToArray(CaseQueryServicePermissionFlags);
    const objectModelCmdPermissions = enumToArray(ObjectModelServicePermissionFlags);
    const objectModelQueryPermissions = enumToArray(ObjectModelServicePermissionFlags);
    //const permissionTemplatePermissions = enumToArray(PermissionTemplatePermissionFlags);

    return (
        <Form
            name="permission-template-form"
            form={form}
            initialValues={{ permission_template_name: initialTemplateName, is_admin: isAdmin }}
            onFinish={onFinish}>

            {customerName &&
                <Form.Item label={t("customer.Customer") }
                    name="customer_name"
                    required="true"
                    rules={[{ required: true, message: t("common.SelectCustomerMessage") }]}>
                    <Input placeholder={t("common.SelectCustomer") } readOnly />
                </Form.Item>
            }

            <Form.Item
                label={t("permission.PermissionTemplateName")}
                name="permission_template_name"
                rules={[{ required: true, message: t("permission.PermissionTemplateNameMessage")  }]}>
                <Input/>
            </Form.Item>

            <Form.Item label={t("permission.IsAdmin") } valuePropName="checked" name="is_admin">
                <Switch onChange={onIsAdminChange}/>
            </Form.Item>

            <Divider/>
            {/*<Row>*/}
            {/*    <Title level={3}>*/}
            {/*        <Text underline>{t("userAccount.UserAccount")}</Text>*/}
            {/*    </Title>*/}
            {/*</Row>*/}
            {/*<Row>*/}
            {/*    <Col span={12}>*/}
            {/*        <PermissionCheckboxList name="userAccountCmdPermissions"*/}
            {/*                                stateChanger={updateInputValues}*/}
            {/*                                optionsArray={userAccountCmdPermissions}*/}
            {/*                                title="Command Permissions"*/}
            {/*                                defaultValues={initItems["userAccountCmdPermissions"]}/>*/}
            {/*    </Col>*/}
            {/*    <Col span={12}>*/}
            {/*        <PermissionCheckboxList name="userAccountQueryPermissions"*/}
            {/*                                stateChanger={updateInputValues}*/}
            {/*                                optionsArray={userAccountQueryPermissions}*/}
            {/*                                title="Query Permissions"*/}
            {/*                                defaultValues={initItems["userAccountQueryPermissions"]}/>*/}
            {/*    </Col>*/}
            {/*    <Divider/>*/}
            {/*</Row>*/}
            {/*<Row>*/}
            {/*    <Title level={3}>*/}
            {/*        <Text underline>{t("person.Person")}</Text>*/}
            {/*    </Title>*/}
            {/*</Row>*/}
            {/*<Row>*/}
            {/*    <Col span={12}>*/}
            {/*        <PermissionCheckboxList name="personCmdPermissions"*/}
            {/*                                stateChanger={updateInputValues}*/}
            {/*                                optionsArray={personCmdPermissions}*/}
            {/*                                title="Command Permissions"*/}
            {/*                                defaultValues={initItems["personCmdPermissions"]}/>*/}
            {/*    </Col>*/}
            {/*    <Col span={12}>*/}
            {/*        <PermissionCheckboxList name="personQueryPermissions"*/}
            {/*                                stateChanger={updateInputValues}*/}
            {/*                                optionsArray={personQueryPermissions}*/}
            {/*                                title="Query Permissions"*/}
            {/*                                defaultValues={initItems["personQueryPermissions"]}/>*/}
            {/*    </Col>*/}
            {/*    <Divider/>*/}
            {/*</Row>*/}            
            <Row>
                <Title level={3}>
                    <Text underline>{t("customer.Customer")}</Text>
                </Title>
            </Row>
            <Row>
                <Col span={12}>
                    <PermissionCheckboxList name="customerCmdPermissions"
                                            stateChanger={updateInputValues}
                                            optionsArray={customerCmdPermissions}
                                            title="Command Permissions"
                                            defaultValues={initItems["customerCmdPermissions"]}/>
                </Col>
                <Col span={12}>
                    <PermissionCheckboxList name="customerQueryPermissions"
                                            stateChanger={updateInputValues}
                                            optionsArray={customerQueryPermissions}
                                            title="Query Permissions"
                                            defaultValues={initItems["customerQueryPermissions"]}/>
                </Col>
                <Divider/>
            </Row>
            <Row>
                <Title level={3}>
                    <Text underline>{t("correctiveMaintenance.CorrectiveMaintenance")}</Text>
                </Title>
            </Row>
            <Row>
                <Col span={12}>
                    <PermissionCheckboxList name="correctiveMaintenanceCmdPermissions"
                                            stateChanger={updateInputValues}
                                            optionsArray={correctiveMaintenanceCmdPermissions}
                                            title="Command Permissions"
                                            defaultValues={initItems["correctiveMaintenanceCmdPermissions"]}/>
                </Col>
                <Col span={12}>
                    <PermissionCheckboxList name="correctiveMaintenanceQueryPermissions"
                                            stateChanger={updateInputValues}
                                            optionsArray={correctiveMaintenanceQueryPermissions}
                                            title="Query Permissions"
                                            defaultValues={initItems["correctiveMaintenanceQueryPermissions"]}/>
                </Col>
                <Divider/>
            </Row>        
            <Row>
                <Title level={3}>
                    <Text underline>{t("objectModelTemplate.ObjectModelTemplate")}</Text>
                </Title>
            </Row>
            <Row>
                <Col span={12}>
                    <PermissionCheckboxList name="objectModelCmdPermissions"
                                            stateChanger={updateInputValues}
                                            optionsArray={objectModelCmdPermissions}
                                            title="Command Permissions"
                                            defaultValues={initItems["objectModelCmdPermissions"]}/>
                </Col>
                <Col span={12}>
                    <PermissionCheckboxList name="objectModelQueryPermissions"
                                            stateChanger={updateInputValues}
                                            optionsArray={objectModelQueryPermissions}
                                            title="Query Permissions"
                                            defaultValues={initItems["objectModelQueryPermissions"]}/>
                </Col>
                <Divider/>
            </Row>
            {/*<Row>*/}
            {/*    <Title level={3}>*/}
            {/*        <Text underline>{t("permission.Permission")}</Text>*/}
            {/*    </Title>*/}
            {/*</Row>*/}
            {/*<Row>*/}
            {/*    <Col span={12}>*/}
            {/*        <PermissionCheckboxList name="permissionTemplatePermissions"*/}
            {/*                                stateChanger={updateInputValues}*/}
            {/*                                optionsArray={permissionTemplatePermissions}*/}
            {/*                                title="Command & Query Permissions"*/}
            {/*                                defaultValues={initItems["permissionTemplatePermissions"]}/>*/}
            {/*    </Col>*/}
            {/*    <Divider/>*/}
            {/*</Row>*/}

            <Space className="float-end mb-3">
                <Button type="primary" htmlType="submit" disabled={saving} loading={saving }>
                    {t("common.Save")}
                </Button>
                <Button onClick={onCancel}>{t("common.Cancel")}</Button>
            </Space>
        </Form>
    );
};

export default PermissionTemplateForm