import * as React from "react";
import { Component } from "react";
import { Route, Switch } from "react-router-dom";
import MainLayout from "./components/MainLayout";
import Home from "./components/Home";

import ListCustomers from "./pages/customer/ListCustomers";
import CreateCustomer from "./pages/customer/CreateCustomer";
import EditCustomer from "./pages/customer/EditCustomer";

import ListIdentities from "./pages/Identity/ListIdentities";
import EditIdentity from "./pages/Identity/EditIdentity";
import CreateCustomerIdentity from "./pages/Identity/CreateCustomerIdentity";

import ListPersons from "./pages/person/ListPersons";
import CreatePerson from "./pages/person/CreatePerson";
import EditPerson from "./pages/person/EditPerson";

import ListPermissionTemplates from "./pages/permissiontemplates/ListPermissionTemplates";
import CreatePermissionTemplate from "./pages/permissiontemplates/CreatePermissionTemplate";
import EditPermissionTemplate from "./pages/permissiontemplates/EditPermissionTemplate";

import ListLanguages from "./pages/language/ListLanguages"
import CreateLanguage from "./pages/language/CreateLanguage";


import "antd/dist/antd.css";
import "./custom.css"
import "./css/util.css"
import "./css/main.css"

export default class App extends Component {

    state = {
        customer: null,
    }
 

    callBackFunction = (childData) => {
        this.setState({ customer: childData });
    }   

    render() {
            return (
                <MainLayout parentCallback={this.callBackFunction}>
                    <Switch>
                        <Route exact path="/">
                            <Home customer={this.state.customer} />
                        </Route>
                        <Route exact path="/customers" component={ListCustomers} />
                        <Route exact path="/customer/create" component={CreateCustomer} />
                        <Route exact path="/customers/:id" component={EditCustomer} />

                        <Route exact path="/identities">
                            <ListIdentities customer={this.state.customer} />
                        </Route>
                        <Route exact path="/identity/create">
                            <CreateCustomerIdentity customer={this.state.customer} />
                        </Route>
                        <Route exact path="/identities/:id/:customerid" component={EditIdentity} />

                        <Route exact path="/persons">
                            <ListPersons customer={this.state.customer} />
                        </Route>
                        <Route exact path="/person/create">
                            <CreatePerson customer={this.state.customer} />
                        </Route>
                        <Route exact path="/persons/:id/:customerid" component={EditPerson} />
                       
                        <Route exact path="/permissiontemplates">
                            <ListPermissionTemplates customer={this.state.customer} />
                        </Route>
                        <Route exact path="/permissiontemplate/create">
                            <CreatePermissionTemplate customer={this.state.customer} />
                        </Route>
                        <Route exact path="/permissiontemplates/:id/:customerId" component={EditPermissionTemplate} />
                                              
                        <Route exact path="/languages">
                            <ListLanguages customer={this.state.customer} />
                        </Route>
                        <Route exact path="/language/create">
                            <CreateLanguage customer={this.state.customer} />
                        </Route>
                    </Switch>
                </MainLayout>
            );
    }
}