import { useState, useEffect } from "react"
import DeDUList from "../../components/List/DeDUList";
import { Spin } from "antd";
import { apiPermission } from "../../services/api/apiPermission";
import { useTranslation } from "react-i18next";


const ListPermissionTemplates = (customer) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [permissionTemplates, setPermissionTemplates] = useState([]);
    const [subTitle, setSubTitle] = useState(t("common.All") + (' ') + t("permission.Permissions"));

    useEffect(() => {
        onSelectedCustomer();
    },
        []);

    useEffect(() => {
        onSelectedCustomer();
    },
        [customer]);

    useEffect(() => {
        if (customer.customer === null) {
            setSubTitle(t("common.All") + (' ') + t("permission.Permissions"));
        }
        else {
            setSubTitle(t("common.All") + (' ') + customer.customer.customerName + (' ') + t("permission.Permissions"));
        }
    },
        [t]);

    const onSelectedCustomer = () => {
        if (customer.customer !== null) {
            getPermissionTemplates();
            setSubTitle(t("common.All") + (' ') + customer.customer.customerName + (' ') + t("permission.Permissions"));
        }
    }

    const getPermissionTemplates = () => {
        setLoading(true);
        apiPermission.getAll(`/${customer.customer.customerId}`)
            .then(data => {
                const customerId = data.customerId;
                const permissionsArray = Object.values(data.userRoles);
                const permissionTemplateData = permissionsArray.map(({ userRoleId, name }) => ({
                    description: userRoleId,
                    title: name,
                    id: userRoleId,
                    customer_id: customerId
                }));
                setPermissionTemplates(permissionTemplateData);
                setLoading(false);
            })
            .catch(error => {
                console.error("There has been an error", error);
                setLoading(false);
            });
    }
    return(
        loading
            ? <div>
                <em>Loading...</em>
                <Spin />
            </div>
            : <DeDUList array={permissionTemplates}
                avatarUrl={"https://www.dedu.se/deduweb/images/dedu-logo.png"}
                mainTitle={t("permission.Permissions")}
                subTitle={subTitle}
                listItemLink={"/permissiontemplates"} />
    );
}
export default ListPermissionTemplates