import { useState, useEffect } from "react";
import { Divider, Form, Input, Button, Spin, Card, Space, Alert } from "antd";
import { apiIdentity } from "../../services/api/apiIdentity";
import { useTranslation } from "react-i18next";

const CreateCustomerIdentity = (customer) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [customerId, setCustomerId] = useState();
    const [customerName, setCustomerName] = useState();
    const [error, setError] = useState();
    const [saving, setSaving] = useState(false);
    const [cratedAccount, setCreatedAccount] = useState({ username: "", password: "" });

    useEffect(() => {
        if (customer.customer !== null) {
            onCustomerSelected();
        }
    }, [customer]);

    const onCustomerSelected = async () => {
        setCustomerId(customer.customer.customerId);
        setCustomerName(customer.customer.customerName);
    };

    const onFinish = (values) => {
        setCreatedAccount({ password: "" });
        setError();

        setSaving(true);
        values["customer_id"] = customerId;       

        apiIdentity.post(JSON.stringify(values))
            .then(rsp => {
                setCreatedAccount({ username: values["email"], password: rsp.password });
                form.resetFields();
                console.log("UserAcccount Created", rsp);
            })
            .catch(error => {
                console.error("There was an error!", error);
                setError("Something went wrong: " + error);
            }).finally(() => {
                setSaving(false);
            });        
    }

    const onCancel = () => {
        
    }

    return (
        <div>
            <h1>{t("userAccount.CreateUserAccount")}</h1>
            <Divider />

            <Form name="basic" form={form} layout="vertical" initialValues={{ remember: true }} onFinish={onFinish}>
                <div className="row">
                    <div className="col-12 col-xl-6 mb-3">
                        <Card size="small" title={t("customer.Customer")}>                            
                            <h6>{customerName}</h6>
                            {!customerId &&
                                <h5 className="text-danger">Select customer first</h5>
                            }
                        </Card>
                        <div className="my-3">
                            <p>Using Admin permission template</p>
                        </div>
                    </div>

                    <div className="col-12 col-xl-6 mb-3">
                        <Card size="small" title={t("userAccount.UserAccount")}>
                            <Form.Item label={t("common.EmailAddress")}
                                name="email"
                                rules={[{
                                        required: true,
                                        type: "email",
                                        message: t("common.EmailAddressMessage")
                                    }]}>
                                <Input  />
                            </Form.Item>                            
                            <Form.Item label={t("person.FirstName")} name="given_name"
                                rules={[{
                                    required: true,
                                    message: t("person.FirstNameMessage")
                                }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={t("person.LastName")} name="family_name"
                                rules={[{
                                    required: true,
                                    message: t("person.LastNameMessage")
                                }]}>
                                <Input />
                            </Form.Item>
                        </Card>
                    </div>

                    {cratedAccount.password &&
                        <div className="col-12 my-3">
                            <Alert type="success"
                                message={"UserAccount for " + customerName + " succesfully created!"}
                                description={"Username: " + cratedAccount.username + " Password: " + cratedAccount.password}                                
                                showIcon
                                closable />
                        </div>
                    }

                    {error && 
                        <div className="col-12 my-3">
                            <Alert message={error} type="error" showIcon closable />
                        </div>
                    }

                    <div className="col-12 my-3">
                        <Space className="float-end">
                            <Button htmlType="button" onClick={onCancel}>
                                {t("common.Cancel")}
                            </Button>
                            <Button type="primary" htmlType="submit" disabled={saving || !customerId} loading={saving}>
                                {t("common.Submit")}
                            </Button>
                        </Space>
                    </div>
                </div>
            </Form>
        </div>
    );
}

export default CreateCustomerIdentity;