import { Form, Checkbox, Col, Typography } from "antd";
import { useEffect, useState } from "react";
const { Title } = Typography;

export const PermissionCheckboxList = ({ name, title, stateChanger, optionsArray, defaultValues }) => {
    const [checkBoxes, setCheckBoxes] = useState([]);

    const getCheckBoxObjects = (items, defaults) => {
        let array = [];
        items.map(item => {
            let checked = defaults.some(d => d === item);
            array.push({ value: item, disabled: false, checked: checked });
        })
        return array;
    }

    const onCheckboxChange = (option, e) => {
        let deselectOthers = false;

        if (option.value === 'None' || option.value === 'All') {
            deselectOthers = true;
        }

        setCheckBoxes(prevState => {
            const newState = prevState.map(obj => {                
                if (obj === option) {
                    return { ...obj, checked: e.checked, disabled: false };
                } else if (deselectOthers) {
                    return { ...obj, checked: false };
                } else if (obj.value === "None" || obj.value === "All") {                    
                    return { ...obj, checked: false };
                }
                return obj;
            });
            
            return newState;
        });
    }

    const updateParent = (checkBoxes) => {
        let values = [];
        checkBoxes.map(cb => cb.checked && values.push(cb.value))
        stateChanger({ name }, values);
    }

    useEffect(() => {        
        updateParent(checkBoxes);
    }, [checkBoxes]);

    useEffect(() => {
        setCheckBoxes(getCheckBoxObjects(optionsArray, defaultValues));
    }, []);

    return (
        <div>
            <Form.Item name={name} valuePropName="checked" noStyle>
                <Title level={4}>{title}</Title>
                <Col span={8}>
                    {checkBoxes.map(option =>
                        <Checkbox className="ml-0"
                            value={option.value}
                            key={option.value}
                            checked={option.checked}
                            disabled={option.disabled }
                            onChange={(e) => onCheckboxChange(option, e.target)}>
                            {option.value}
                        </Checkbox>
                    )}
                </Col>
            </Form.Item>
        </div>
    );
};