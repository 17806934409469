/*/*Until we figure out way to use C# enums from contract, a copy enum here*/
export enum CaseCommandServicePermissionFlags {
    None = 0,
    CanCreateCase = 1,
    CanWriteCase = 1 << 1,
    CanCloseCase = 1 << 2,
    CanCreateWorkOrder = 1 << 3,
    CanWriteWorkOrder = 1 << 4,
    CanCloseWorkOrder = 1 << 5,
    CanCreateOpType = 1 << 20,
    CanWriteOpType = 1 << 22,
    All = ~None
}

export enum CaseQueryServicePermissionFlags {
    None = 0,
    CanReadCase = 1 << 1,
    CanReadHistory = 1 << 2,
    CanReadOpType = 1 << 20,
    All = ~None
}