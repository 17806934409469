import { useEffect, useState } from 'react';
import { Menu, Dropdown, Spin } from "antd";
import { apiIdentity } from "../services/api/apiIdentity";
import LanguageSwitcher from "../components/LanguageSwitcher";

const UserDropdown = ({ logoutUrl }) => {
    const [loading, setLoading] = useState(true);
    const [userName, setUserName] = useState();

    const getIdentity = () => {
        apiIdentity.getAll('/loggedinuser').then(data => {
            //console.log("identity", data);
            setUserName(data.username);
        }).catch(err => {
            console.error(err);
        }).finally(() => {
            setLoading(false);
        });        
    }

    const logout = () => {
        //console.log(logoutUrl);
        window.location.replace(logoutUrl);
    }

    useEffect(() => {
            getIdentity();            
        }, []);

    const menu = (
        <Menu className="mt-1">
            <Menu.Item key="1">
                <LanguageSwitcher/>
            </Menu.Item>

            <Menu.Item key="2" onClick={logout}>
                <div className="px-1">
                    Logout
                    <i className="fs-6 fas fa-sign-out-alt float-end mt-1"></i>
                </div>
            </Menu.Item>
        </Menu>
    );

    return (
        <Dropdown overlay={menu}>
            <span className="ant-dropdown-link">
                <div className="fs-18 text-white">
                    <Spin spinning={loading} />
                    <span className="me-2">{userName}</span>
                    <i className="fs-6 fas fa-chevron-down ms-1 text-white"></i>
                </div>                
            </span>
        </Dropdown>
    );
}

export default UserDropdown;